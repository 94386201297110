import { graphql, StaticQuery } from "gatsby";
import parse from "html-react-parser";
import React, { useState, useRef, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slide from "../components/utility/slide_content";
import Fade from "react-reveal/Fade";
import CustomAccordion from "../components/common/accordian";
import Buttons from "../components/common/button";
import CarouselComponent from "../components/common/carousel-component";
import FormBuilder from "../components/common/FormBuilder/Form-Builder";
import handleFormSubmit from "../components/common/FormBuilder/Form-Function";
import Frame from "../components/common/frame";
import SVGIcon from "../components/common/SVGIcon";
import Navigation from "../components/navigation";
import "../styles/component/faq.scss";
import "../styles/component/pricing-faq.scss";
import "../styles/pages/Supersummit-2022.scss";
import "../styles/pages/supersummit-2023.scss";
import "../styles/pages/supercon-2022.scss";
import "../styles/component/popup.scss";
import "../styles/component/forms.scss";
import "../styles/pages/Virtual-2023.scss";
import "../styles/pages/virtual-summit-2024.scss";
import Popper from "./../assets/images/party-popper.svg";

const Virtual2024 = () => {
  const [popUp, setPopUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [registeredMails, setRegisteredMails] = useState();
  let [activeKey, setActiveKey] = useState(0);

  const loadRef = useRef();

  loadRef.current = setLoading;

  const formdata = [
    {
      name: "firstname",
      type: "text",
      label: "Name",
      errorMessage: "Please enter a name",
    },
    {
      name: "email",
      type: "email",
      label: "Email address",
      errorMessage: "Please enter a valid email",
    },
    {
      name: "company",
      type: "text",
      label: "Company name",
      errorMessage: "Please enter a company name",
    },
    {
      name: "phone",
      type: "phone",
      label: "Mobile number",
      errorMessage: "Please enter a mobile number",
    },
  ];

  async function handleSubmit(items) {
    const formData = items;
    setLoading(true);
    sessionStorage.setItem("superSummitName", items[0].value);
    sessionStorage.setItem("superSummitEmail", items[1].value);
    var successval = handleFormSubmit(
      formData,
      process.env.HUBSPOT_SUPERSUMMIT_VIRTUAL_2023_ENDPOINT,
      window.location.href,
      "Supersummit Virtual 2024 - Superops",
      null,
      null,
      null,
      () => setSuccess(true)
      // `success?refererName=${items[0].value}&&refererEmail=${items[1].value}`,
    );
  }

  const responsiveSpeakers = {
    tablet: {
      breakpoint: { max: 992, min: 500 },
      items: 1.7,
    },
    mobile5: {
      breakpoint: { max: 500, min: 400 },
      items: 1.45,
    },
    mobile4: {
      breakpoint: { max: 400, min: 0 },
      items: 1.3,
    },
  };

  const PopUpForm = () => {
    return (
      <div className="popup-signup">
        <div class="wrap position-fixed"></div>
        <Container className="position-fixed">
          <div className="Form-box position-relative">
            <SVGIcon
              name="close-icon-black"
              className="close-icon"
              onClick={() => setPopUp(false)}
            />
            {!success ? (
              <>
                <FormBuilder
                  formClassName="forms"
                  errorClassName="error-message"
                  inputErrorClassName="error-active"
                  data={formdata}
                  formmessage="New to SuperSummit? Register Now!"
                  buttonText={
                    loading ? (
                      <div className="loading-anim">
                        LOADING
                        <span className="ellipsis-anim">
                          <span>.</span>
                          <span>.</span>
                          <span>.</span>
                        </span>
                      </div>
                    ) : (
                      "REFER NOW"
                    )
                  }
                  extradata={{
                    name: "outbound_source",
                    value: "SuperSummit - Virtual 2024",
                  }}
                  buttonClassName="primary pink"
                  onSubmit={(items) => handleSubmit(items)}
                  IpStackData
                  preRegister={{
                    title: "Already registered?",
                    label: "Email address",
                    errorMessage: "Please enter the registered email",
                    btnText: "WATCH NOW",
                    registeredMails: registeredMails,
                    dividerTxt: "(OR)",
                  }}
                />
                {/* <div className="disclaimer p12 a12">
                  <center>
                    <p>
                      By clicking "SUBMIT", you agree to SuperOps'{" "}
                      <a href="https://superops.com/terms" target="_blank">
                        Terms of use
                      </a>{" "}
                      and{" "}
                      <a href="https://superops.com/privacy" target="_blank">
                        Privacy policy
                      </a>
                      .
                    </p>
                  </center>
                </div> */}
              </>
            ) : (
              <>
                <div className="success-box text-center">
                  <img src={Popper} alt="popper" />

                  <h4 className="ty font-roboto text-white fw-600 mb24">
                    Hooray!
                  </h4>

                  <div className="p16 mb10">
                    <p className="m-0 d-inline">
                      You are seconds away from 10+ hours of expert-led content!{" "}
                    </p>
                    <p className="m-0 d-inline">
                      Access SuperSummit Virtual 2024 using the unique{" "}
                      <span className="text-pink">link in your email.</span>
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </Container>
      </div>
    );
  };

  const TrackTimeline = ({ timeline }) => {
    return timeline.map((item) => {
      return (
        <>
          <Row className="d-flex justify-content-center">
            <Col lg={2} className="session-time">
              <p className="title-color">{item.agenda}</p>
              <p className="font-white mb24">{item.time}</p>
            </Col>
            <Col lg={4}>
              {item.title[0] && <p className="title-color">Title</p>}
              {item.title[0] && (
                <p className="font-white font-roboto m-0">{item.title[0]}</p>
              )}
              {item.description[0] && (
                <p className="desp-color description">{item.description[0]}</p>
              )}
              {item.tag && (
                <p className="font-white fw-600 tag position-relative">
                  {item.tag}
                </p>
              )}
            </Col>
            <Col lg={4}>
              {item.speakerName[0] && (
                <>
                  <p className="title-color">Speaker</p>
                  {item.speakerName.map((speaker, i) => {
                    return (
                      <div className="speaker-box d-flex mb24 gap-24">
                        <div>
                          <img
                            src={item.speaker[i]?.url}
                            className="speaker-image br10"
                            alt="speaker"
                          />
                        </div>

                        <div>
                          <p className="speaker-name font-white font-roboto m-0">
                            {speaker}
                          </p>
                          <p className="speaker-title desp-color p14">
                            {item.speakerTitle[i]}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </Col>
          </Row>
          <div className="divider-line" />
        </>
      );
    });
  };

  const KeynoteTimeline = ({ timeline }) => {
    return timeline.map((item) => {
      return (
        <>
          <Row className="d-flex justify-content-end">
            <Col lg={2} className="session-time">
              <p className="title-color">{item.agenda}</p>
              <p className="font-white mb24">{item.time}</p>
            </Col>
            <Col lg={9}>
              {item.title[0] && <p className="title-color">Title</p>}
              {item.title[0] && (
                <p className="font-white font-roboto m-0">{item.title[0]}</p>
              )}
              {item.description[0] && (
                <p className="desp-color description">{item.description[0]}</p>
              )}
              {item.speakerName[0] && (
                <>
                  <p className="title-color">Speaker</p>
                  <Row className="flex-wrap">
                    {item.speakerName.map((speaker, i) => {
                      return (
                        <Col lg={4} className="speaker-box d-flex mb24 gap-24">
                          <div>
                            <img
                              src={item.speaker[i]?.url}
                              className="speaker-image br10"
                              alt="speaker"
                            />
                          </div>

                          <div>
                            <p className="speaker-name font-white font-roboto m-0">
                              {speaker}
                            </p>
                            <p className="speaker-title desp-color p14">
                              {item.speakerTitle[i]}
                            </p>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </>
              )}
            </Col>
          </Row>
          <div className="divider-line" />
        </>
      );
    });
  };

  const CentralTrack = ({ multiTimeline }) => {
    if (activeKey === 0) {
      return <KeynoteTimeline timeline={multiTimeline[0].allTimeline} />;
    } else if (activeKey === 1) {
      return <TrackTimeline timeline={multiTimeline[1].allTimeline} />;
    } else {
      return <TrackTimeline timeline={multiTimeline[2].allTimeline} />;
    }
  };

  return (
    <div>
      <StaticQuery
        query={graphql`
          query Virtual2024 {
            SuperOps {
              pageComponents(
                where: { pageName: "SuperSummit - Virtual - 2024" }
              ) {
                seo {
                  keywords
                  description
                  title
                  image {
                    id
                    url
                  }
                }
                faqs {
                  category
                  question
                  answerrt {
                    html
                  }
                }
                newfield {
                  ... on SuperOps_Card {
                    cardName
                    cardDescription
                    heading
                    title
                    slug
                    content {
                      html
                    }
                    text
                    subtext
                    image {
                      url
                    }
                    picture {
                      url
                    }
                  }
                }
                multiTimeline {
                  __typename
                  ... on SuperOps_OnlyTimeline {
                    allTimeline {
                      __typename
                      ... on SuperOps_Timeline {
                        time
                        agenda
                        title
                        description
                        tag
                        speaker {
                          url
                        }
                        speakerName
                        speakerTitle
                      }
                    }
                  }
                }
              }
              jsonObjects(where: { title: "SuperSummit - Virtual - 2024" }) {
                emailId
              }
            }
          }
        `}
        render={(data) => (
          <>
            {data.SuperOps.pageComponents.map((page) => {
              const { seo, faqs, newfield, multiTimeline } = page;
              setRegisteredMails(data.SuperOps.jsonObjects[0].emailId[0].data);
              const hero = newfield[0];
              // const sponsers = newfield[3];
              const speakers = newfield[4];
              return (
                <div className="virtual-summit-2024 Supersummit-2022 Supersummit-2023 Supersummit-2024 virtual-2023">
                  <Frame
                    seo={seo}
                    FooterName="Super-Summit"
                    footerClassName="super-summit pt-0"
                    twitterOG
                  >
                    {popUp && <PopUpForm />}

                    <header>
                      <Navigation
                        page="Supersummit-2022"
                        tabs={[
                          { name: "why attend", id: "why_attend_id" },
                          { name: "speakers", id: "speakers_id" },
                          { name: "agenda", id: "agenda_id" },
                          // { name: "sponsers", id: "sponsers_id" },
                          { name: "faq", id: "faq_id" },
                        ]}
                        color={"transparent"}
                        setPopUp={setPopUp}
                        btnText="WATCH ON-DEMAND"
                      />
                    </header>

                    <section className="hero position-relative">
                      <video
                        className="bg-video d-dsk"
                        autoPlay
                        muted
                        loop
                        id="bg-video"
                      >
                        <source
                          src={
                            "https://media.graphassets.com/DclXgTpTf2PzhaXD0Inw"
                          }
                          type="video/mp4"
                        />
                      </video>
                      <Container className="Layout-container position-relative">
                        <div className="d-flex justify-content-center hero-gap flex-wrap">
                          <Col
                            lg={4}
                            className="d-flex flex-column align-items-center"
                          >
                            <div className="col-one">
                              <img
                                src={hero.image[0].url}
                                className="submit-logo down-to-up-1 w-100 mb30"
                                alt=""
                              />
                              <div className="d-flex justify-content-between down-to-up-1">
                                {hero.picture.map((item, idx) => {
                                  return (
                                    <div className="logo-text-wrap">
                                      <div className="d-flex align-items-center gap-8">
                                        <img src={item.url} alt="logo" />
                                        <p className="font-white m-0 p14">
                                          {hero.text[idx]}
                                        </p>
                                      </div>
                                      <p className="font-white fw-bold p16 m-0">
                                        {hero.subtext[idx]}
                                      </p>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </Col>
                          <Col lg={5}>
                            <img
                              src={hero.image[1].url}
                              className="nav-logo down-to-up-1"
                              alt=""
                            />
                            <Buttons
                              theme="primary pink w-100 button down-to-up-1"
                              text={hero.title[0]}
                              onClick={() => setPopUp(true)}
                            />
                          </Col>
                        </div>
                        {/* <div
                          id="why_attend_id"
                          className="prize-box mx-auto font-white fw-600 down-to-up-2"
                        >
                          {parse(hero.content[0].html)}
                        </div> */}
                      </Container>
                      <div className="position-absolute bg-smoother d-dsk"></div>
                    </section>

                    <section className="about position-relative">
                      <Container className="Layout-container d-flex justify-content-center">
                        <Col lg={10}>
                          <Fade bottom>
                            <p className="font-pink tag p14 mb16">
                              {newfield[1].cardName}
                            </p>
                            {newfield[1].heading.map((item) => {
                              return <p className="font-white p16">{item}</p>;
                            })}
                          </Fade>
                        </Col>
                      </Container>
                    </section>

                    <section className="track-detail mt80">
                      <Container className="Layout-container d-flex justify-content-center">
                        <Col lg={10}>
                          <Slide delay="100">
                            <h3 className="font-white fw-600 font-roboto mb16">
                              {newfield[2].cardName}
                            </h3>
                            <p className="font-white p16 mb40">
                              {newfield[2].cardDescription}
                            </p>
                          </Slide>

                          <div className="row mb40 row-gap-48">
                            {newfield[2].heading.map((item, ind) => {
                              return (
                                <Col lg={6}>
                                  <Slide delay="100">
                                    <p className="tag text-pink">{item}</p>
                                    <p className="number">0{ind + 1}</p>
                                    <p className="font-white title mb24">
                                      {newfield[2].title[ind]}
                                    </p>
                                    <div className="points">
                                      {newfield[2].text.map((el, idx) => {
                                        return (
                                          <div className="d-flex gap-8">
                                            <img
                                              src={newfield[2].image[0].url}
                                              alt="star"
                                              className="star"
                                            />
                                            <p className="font-white mb30">
                                              {ind === 0
                                                ? el
                                                : newfield[2].subtext[idx]}
                                            </p>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </Slide>
                                </Col>
                              );
                            })}
                          </div>
                          <h4 className="font-roboto font-white">
                            {newfield[2].slug}
                          </h4>
                        </Col>
                      </Container>
                      <p id="speakers_id" />
                    </section>

                    {/* <section>
                      <Fade>
                        <img
                          src={newfield[2].picture[0].url}
                          className="w-100 d-dsk"
                        />
                        <img
                          src={newfield[2].picture[1].url}
                          className="w-100 d-mob"
                        />
                      </Fade>
                    </section> */}

                    <section className="speakers">
                      <Container className="Layout-container">
                        <Fade bottom>
                          <p className="text-pink tag fw-500 p14 mb8">
                            {speakers.cardName}
                          </p>
                          <h2 className="font-white font-roboto mb40">
                            {speakers.cardDescription}
                          </h2>
                          <div className="d-flex flex-wrap gap-24 row-gap-24 d-dsk">
                            {speakers.image.map((img, ind) => {
                              return (
                                <Col
                                  lg={3}
                                  className="speaker-card position-relative"
                                >
                                  <div className="overflow-hidden br10">
                                    <img
                                      src={img?.url}
                                      alt={speakers.text[ind]}
                                      className="image w-100"
                                    />
                                  </div>

                                  <p className="p24 font-white fw-bold name font-roboto">
                                    {speakers.text[ind]}
                                  </p>
                                  <p className="p14 designation">
                                    {speakers.subtext[ind]}
                                  </p>
                                </Col>
                              );
                            })}
                          </div>
                        </Fade>
                      </Container>
                      <div className="cards-wrapper-mob d-mob">
                        <Slide delay="100">
                          <CarouselComponent
                            responsive={responsiveSpeakers}
                            status
                            statusTextRight="speakers"
                            swipeable
                            autoPlay={false}
                          >
                            {speakers.image.map((img, ind) => {
                              return (
                                <Col
                                  lg={3}
                                  className="speaker-card position-relative"
                                >
                                  <div className="overflow-hidden br10">
                                    <img
                                      src={img?.url}
                                      alt={speakers.text[ind]}
                                      className="image w-100"
                                    />
                                  </div>
                                  <p className="p24 font-white fw-bold name font-roboto">
                                    {speakers.text[ind]}
                                  </p>
                                  <p className="p14 designation">
                                    {speakers.subtext[ind]}
                                  </p>
                                </Col>
                              );
                            })}
                          </CarouselComponent>
                        </Slide>
                      </div>
                      <p id="agenda_id" />
                    </section>

                    <section className="timelines">
                      <Container className="Layout-container">
                        <Col lg={11} className="tab-wrap">
                          <Fade bottom>
                            <div className="d-flex align-items-end track-tabs mb80">
                              <p
                                className={`font-roboto p16 fw-600 m-0 bor-bot trans cursor-pointer keynote ${
                                  activeKey === 0 ? "active" : null
                                }`}
                                onClick={() => setActiveKey(0)}
                              >
                                Keynote
                              </p>
                              {newfield[5].text.map((item, ind) => {
                                return (
                                  <div
                                    className={`cursor-pointer`}
                                    onClick={() => {
                                      setActiveKey(ind + 1);
                                    }}
                                  >
                                    <p
                                      className={`track-no trans p14 fw-500 ${
                                        activeKey === ind + 1 ? "active" : null
                                      }`}
                                    >
                                      {item}
                                    </p>
                                    <p
                                      className={`font-roboto m-0 trans p16 fw-600 bor-bot ${
                                        activeKey === ind + 1
                                          ? " font-white"
                                          : null
                                      }`}
                                    >
                                      {newfield[5].subtext[ind]}
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          </Fade>
                        </Col>
                        <Fade bottom>
                          <CentralTrack multiTimeline={multiTimeline} />
                        </Fade>
                      </Container>
                      <p id="sponsers_id" />
                    </section>

                    {/* <section className="sponsers">
                      <Container className="Layout-container">
                        <Row>
                          <p className="text-pink tag mb8">
                            {sponsers.cardName}
                          </p>
                          {sponsers.heading.map((item, ind) => {
                            const images = ind
                              ? sponsers.picture
                              : sponsers.image;
                            return (
                              <Col
                                lg={6}
                                className={`${
                                  ind === 0 ? "gold" : "silver"
                                } mb40`}
                              >
                                <Fade bottom>
                                  <h2 className="font-roboto font-white title mb40">
                                    {item}
                                  </h2>
                                  <div className="d-flex justify-content-between flex-wrap row-gap-24">
                                    {images.map((img, ind) => {
                                      return (
                                        <div className="outer-box d-flex justify-content-center align-items-center position-relative">
                                          <div className="img-wrap">
                                            <img
                                              src={img.url}
                                              alt="sponser"
                                              className="w-100"
                                            />
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </Fade>
                              </Col>
                            );
                          })}
                        </Row>
                      </Container>
                      <p id="faq_id" />
                    </section> */}

                    <section className="Faq-section">
                      <Container>
                        <div className="faq">
                          <div className="outer mx-auto">
                            <Fade bottom>
                              <h2 className="faq-heading text-left font-roboto">
                                We are sure you have questions
                              </h2>
                            </Fade>
                            <CustomAccordion items={faqs} />
                          </div>
                        </div>
                      </Container>
                    </section>
                  </Frame>
                </div>
              );
            })}
          </>
        )}
      />
    </div>
  );
};

export default Virtual2024;
